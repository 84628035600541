import { createApiClient } from './axios';

type Env =
  | 'REACT_APP_API_URL'
  | 'REACT_APP_API_MOCK_URL'
  | 'REACT_APP_BUDGET_MS_URL'
  | 'REACT_APP_SEARCH_MS_URL';

const env = (key: Env): string => {
  const value = process.env[key];
  if (!value) throw new Error(`${key} is not set.`);
  return value;
};

export const api = createApiClient({
  baseURL: env('REACT_APP_API_URL'),
});

export const mockApi = createApiClient({
  baseURL: env('REACT_APP_API_MOCK_URL'),
});
