import React from 'react';

import StatusCircle from 'components/StatusCircle';

import { Subscription } from 'types/access-control';

export const getStatusBadge = (
  item: Subscription['histories']['0'],
): React.ReactElement => {
  if (item.status === 'approved') {
    return <StatusCircle colorScheme="green">Aprovado</StatusCircle>;
  }

  if (item.status === 'delayed') {
    return <StatusCircle colorScheme="yellow">Em atraso</StatusCircle>;
  }

  if (item.status === 'chargeback') {
    return <StatusCircle colorScheme="orange">Chargeback</StatusCircle>;
  }

  if (item.status === 'refunded') {
    return <StatusCircle colorScheme="red">Recusado</StatusCircle>;
  }

  return <StatusCircle colorScheme="gray">Outro</StatusCircle>;
};
