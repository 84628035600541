import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { isSuperOrEcustosAdmin } from 'shared/roles';
import { toast } from 'shared/toast';

import { UserOrganization } from 'types/access-control';

import useCookies from './useCookies';

export interface Auth {
  user: UserOrganization;
  token: string | null;
  validating: boolean;
  loading: boolean;
  authenticated: boolean;
  handleLogin(email: string, password: string): Promise<void>;
  handleTwoFactorAuth(email: string, code: string): Promise<void>;
  handleLogout(): void;
  getAuth(): Promise<void>;
}

const domain = process.env.NODE_ENV === 'development' ? '' : '.ecustos.com.br';

const cookieOptions = {
  path: '/',
  domain,
  expires: 15, // 15 days
};

const getCookieKeys = (): string => {
  const env = process.env.REACT_APP_ECUSTOS_ENV;

  if (env === 'production') {
    return 'ecustos-admin-auth-prd';
  }

  if (env === 'staging') {
    return 'ecustos-admin-auth-stg';
  }

  return 'ecustos-admin-auth-dev';
};

const getRedirectUrl = (): string => {
  const env = process.env.REACT_APP_ECUSTOS_ENV;

  if (env === 'production') {
    return 'https://v2.ecustos.com.br';
  }

  if (env === 'staging') {
    return 'https://stage.v2.ecustos.com.br/';
  }

  return 'http://localhost:3000';
};

function useAuth(): Auth {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const cookieTokenKey = getCookieKeys();

  const [token, setToken, removeToken] = useCookies(cookieTokenKey);

  const [validating, setValidating] = useState<boolean>(true);

  const [user, setUser] = useState<UserOrganization>({} as UserOrganization);

  const shouldAccessApplication = useCallback((userInfo) => {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }

    if (!userInfo || !userInfo.roles) {
      return false;
    }

    return isSuperOrEcustosAdmin(userInfo);
  }, []);

  const getAuth = useCallback(async () => {
    setValidating(true);

    try {
      const { data: userInfo } = await api.get('/auth');

      if (!shouldAccessApplication(userInfo.data)) {
        setValidating(false);
        setAuthenticated(false);
        removeToken();
        setUser({} as UserOrganization);

        delete api.defaults.headers.Authorization;
        delete api.defaults.headers.common['JWT-Management'];

        window.location.href = getRedirectUrl();
        return;
      }

      setUser(userInfo.data);
      setAuthenticated(true);
    } catch (err) {
      setValidating(false);
      setAuthenticated(false);
      removeToken();
      setUser({} as UserOrganization);

      delete api.defaults.headers.Authorization;
      delete api.defaults.headers.common['JWT-Management'];

      toast({
        description: 'Sessão expirada! Faça login novamente.',
        status: 'warning',
      });
    } finally {
      setValidating(false);
    }
  }, [removeToken, setUser, setAuthenticated, shouldAccessApplication]);

  const getUserOrganizationDefault = useCallback(
    async (organizationId = null, userId = null) => {
      setLoading(true);

      try {
        const { data: userInfo } = await api.get('/user/organization/default', {
          params: {
            ...(organizationId && { organization_id: organizationId }),
            ...(userId && { user_id: userId }),
          },
        });
        return userInfo.data;
      } catch (err) {
        if (isAxiosError(err)) {
          toast({
            description:
              err.response?.data.message ||
              'Houve um erro ao buscar as informações do usuário.',
            status: 'error',
          });

          setValidating(false);
          setAuthenticated(false);
          removeToken();
          setUser({} as UserOrganization);
        }
      } finally {
        setLoading(false);
      }

      return null;
    },
    [removeToken, setUser, setAuthenticated],
  );

  const handleLogout = useCallback(
    (force?: boolean): void => {
      setValidating(true);

      try {
        if (!force) {
          api.delete('/auth');
        }
      } finally {
        setValidating(false);
        setAuthenticated(false);
        removeToken();
        setUser({} as UserOrganization);

        delete api.defaults.headers.Authorization;
        delete api.defaults.headers.common['JWT-Management'];

        history.push('/');
      }
    },
    [history, removeToken],
  );

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      await api.post('/auth', {
        email,
        password,
      });

      history.push(`/2fa?email=${encodeURIComponent(email)}`);
    } catch (err) {
      if (isAxiosError(err)) {
        const message =
          err.response?.data.message ||
          'Houve um erro inesperado ao realizar o login.';
        throw new Error(message);
      }
    }
  };

  const handleTwoFactorAuth = async (
    email: string,
    code: string,
  ): Promise<void> => {
    try {
      const authUser = await api.post('/auth/code', {
        email,
        code,
      });

      const accessToken = authUser.data?.accessToken;

      if (accessToken) {
        api.defaults.headers.Authorization = `Bearer ${accessToken}`;
        setToken(accessToken, cookieOptions);

        const userInfo = await getUserOrganizationDefault(null, null);

        if (isSuperOrEcustosAdmin(userInfo)) {
          getAuth();
          history.push('/');
        }
      }
    } catch (err) {
      if (isAxiosError(err)) {
        const message =
          err.response?.data.message ||
          'Houve um erro inesperado ao realizar o login.';
        throw new Error(message);
      }
    }
  };

  useEffect(() => {
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
      getAuth().then(() => {
        // history.push('/');
      });
    } else {
      setValidating(false);
      setAuthenticated(false);
      setUser({} as UserOrganization);
    }
  }, [token, getAuth, setToken, history]);

  return {
    authenticated,
    user,
    validating,
    token,
    loading,
    handleLogin,
    handleTwoFactorAuth,
    handleLogout,
    getAuth,
  };
}

export type { UserOrganization };
export default useAuth;
