import React from 'react';
import { components, LoadingIndicatorProps } from 'react-select';

import Loader from 'components/Loader';

const LoadingIndicator: React.FC<LoadingIndicatorProps> = (props) => {
  return (
    components.LoadingIndicator && (
      <components.LoadingIndicator {...props}>
        <Loader />
      </components.LoadingIndicator>
    )
  );
};

export default LoadingIndicator;
