import React from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

import { ChevronUp as UpCircleIcon } from '@styled-icons/entypo/ChevronUp';
import { motion } from 'framer-motion';

import { Icon } from '@chakra-ui/react';

const variants = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const UpCircleIconMotion = motion(Icon);

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  const { selectProps } = props;

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <UpCircleIconMotion
          as={UpCircleIcon}
          animate={selectProps.menuIsOpen ? 'open' : 'closed'}
          variants={variants}
          w={5}
          h={5}
          color="gray.500"
        />
      </components.DropdownIndicator>
    )
  );
};

export default DropdownIndicator;
