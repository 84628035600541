import React from 'react';

import { Spinner, SpinnerProps } from '@chakra-ui/react';

import { Container } from './styles';

const Loader: React.FC<SpinnerProps> = (props) => {
  return (
    <Container>
      <Spinner {...props} />
    </Container>
  );
};

export default Loader;
