import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function formatDate(date: string | Date): string {
  return format(new Date(date), 'dd/MM/yyyy HH:mm');
}

export function toFilterFormat(date: string | Date): string {
  return format(utcToZonedTime(new Date(date), 'UTC'), 'yyyy-MM-dd HH:mm');
}
