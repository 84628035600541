import React from 'react';

import { ModalProps as ChakraModalProps } from '@chakra-ui/react';

import useModal, { ModalProps } from 'hooks/useModal';

import modalList from './modalList';

type ChakraProps = Omit<ChakraModalProps, 'children'>;

export interface ModalRootProps extends ModalProps, ChakraProps {
  handleClose: () => void;
}

export type ModalKey = keyof typeof modalList;

const ModalRoot: React.FC = () => {
  const { modals, closeModal } = useModal();

  return (
    <>
      {modals.map(({ key, props }) => {
        const Component = modalList[key];

        if (!Component) return null;

        return (
          <Component
            key={key}
            isOpen
            isCentered
            mode="add"
            onClose={() => closeModal(key)}
            handleClose={() => closeModal(key)}
            closeOnOverlayClick={false}
            {...props}
          />
        );
      })}
    </>
  );
};

export default ModalRoot;
