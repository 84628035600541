import { useContext } from 'react';

import ModalContext, { IModalContext } from 'contexts/ModalContext';

function useModal(): IModalContext {
  const modal = useContext(ModalContext);

  if (!modal) {
    throw new Error('You have to use "useModal()" inside ModalProvider');
  }

  return modal;
}

export type { ModalProps, IModalContext } from 'contexts/ModalContext';

export default useModal;
