import React, { useState, useRef } from 'react';

import { api } from 'services/api';
import { isAxiosError } from 'services/axios';
import { toast } from 'shared/toast';
import * as yup from 'yup';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

const ModalUserInvite: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const emailValidator = yup.string().email();
  const emailRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState<string>();
  const [name, setName] = useState<string>();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors | undefined>();

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!name) {
      Object.assign(formErrors, { name: true, type: 'required' });
    }

    if (!email) {
      Object.assign(formErrors, { email: true, type: 'required' });
    }

    if (!(await emailValidator.isValid(email))) {
      Object.assign(formErrors, { email: true, type: 'invalid' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      await api.post(`/user/invite`, {
        name,
        email,
        role: 'ecustos-admin',
      });

      toast({
        description: `Foi enviado um e-mail com o convite para ${email}.`,
        status: 'success',
      });

      if (onConfirm) onConfirm();
      handleClose();
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description:
            err.response?.data?.message || 'Houve um erro ao convidar usuário',
          status: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Adicionar usuário administrador (suporte)</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors?.name}>
            <FormLabel>Nome</FormLabel>

            <Input
              w="100%"
              placeholder="Ex: João da Silva"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && emailRef.current?.focus()}
            />

            <FormErrorMessage>
              {!!errors?.name && 'Nome é obrigatório'}
            </FormErrorMessage>
          </FormControl>

          <FormControl mt={4} isInvalid={!!errors?.email}>
            <FormLabel>E-mail</FormLabel>
            <Input
              w="100%"
              placeholder="Ex: joao@email.com"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
            />

            <FormErrorMessage>
              {!!errors?.email && errors?.type === 'invalid'
                ? 'E-mail inválido'
                : 'E-mail é obrigatório'}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUserInvite;
