import { useContext } from 'react';

import AlertContext, { IAlertContext } from 'contexts/AlertContext';

function useAlert(): IAlertContext {
  const alert = useContext(AlertContext);

  if (!alert) {
    throw new Error('You have to use "useAlert()" inside AlertProvider');
  }

  return alert;
}

export type { AlertProps, IAlertContext } from 'contexts/AlertContext';

export default useAlert;
