import React from 'react';

import { formatDate } from 'shared/date';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  HStack,
  useMediaQuery,
} from '@chakra-ui/react';

import AppTable from 'components/AppTable';
import { ModalRootProps } from 'components/Modal/Root';

import { Subscription } from 'types/access-control';

import { getPaymentMethod } from './getPaymentMethod';
import { getStatusBadge } from './getStatusBadge';

interface ModalSubscriptionHistoryProps extends ModalRootProps {
  data: {
    histories: Subscription['histories'];
  };
}

const modalMargin = 300;
const modalMobileFix = modalMargin - 20;

const ModalSubscriptionHistory: React.FC<ModalSubscriptionHistoryProps> = ({
  handleClose,
  data: { histories },
  ...restProps
}) => {
  const [isMobile] = useMediaQuery('(max-width: 728px)');

  const handleConfirm = (): void => {
    handleClose();
  };

  const reversedHistories = [...histories].reverse();

  return (
    <Modal {...restProps} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        sx={{
          width: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxWidth: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          height: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
          maxHeight: `calc(100% - ${
            isMobile ? modalMargin - modalMobileFix : modalMargin
          }px)`,
        }}
      >
        <ModalHeader>Histórico da transação</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <AppTable
            cols={[
              {
                field: 'transaction_id',
                description: 'ID da transação',
                sortable: false,
              },
              {
                field: 'created_at',
                description: 'Data e hora',
                sortable: false,
              },
              { field: 'status', description: 'Status', sortable: false },
              {
                field: 'payment_method',
                description: 'Método de pagamento',
                sortable: false,
              },
            ]}
            mapping={(item) => ({
              id: item.id,
              transaction_id: item.transaction_id,
              created_at: formatDate(item.created_at),
              status: getStatusBadge(item),
              payment_method: getPaymentMethod(item.payment_method),
              actions: null,
            })}
            data={reversedHistories}
            noDataMessage="Nenhuma ação registrada."
            loading={false}
            onUpdateSort={() => {
              //
            }}
          />
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleConfirm}>Fechar</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSubscriptionHistory;
