import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  Grid,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';
import OwnSelect, { SelectedValues } from 'components/OwnSelect';

import { Capability } from 'types/access-control';
import { Base } from 'types/base';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

type CapabilityPostPayload = Array<{
  key: string;
  value: number[] | number | string | string[];
}>;

type CapabilityPutPayload = Array<{
  id: number;
  value: number[] | number | string | string[];
}>;

interface ModalPlanProps extends ModalRootProps {
  data: {
    plan_id?: number;
    description?: string;
    external_id?: string;
    bases?: number[];
    use_bases_capability_id?: number;
    use_flow_capability_id?: number;
    use_template_capability_id?: number;
    use_parametric_budget_capability_id?: number;
    number_of_users?: number;
    number_of_users_capability_id?: number;
    integrations: Capability[];
  };
}

function getMissingElements(arr1: string[], arr2: string[]): string[] {
  return arr1.filter((element) => !arr2.includes(element));
}

const ModalPlanAdd: React.FC<ModalPlanProps> = ({
  onConfirm,
  handleClose,
  data,
  mode,
  ...restProps
}) => {
  const [description, setDescription] = useState(data.description || '');
  const [externalId, setExternalId] = useState(data.external_id || '');
  const [numberOfUsers, setNumberOfUsers] = useState(data.number_of_users || 0);

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const externalIdRef = React.useRef<HTMLInputElement>(null);
  const numberOfUsersRef = React.useRef<HTMLInputElement>(null);

  const [isAllBases, setIsAllBases] = useState(false);
  const [baseSelected, setBaseSelected] = useState<number[]>(data.bases || []);
  const [baseList, setBaseList] = useState<Base[]>([]);

  const [integrationSelected, setIntegrationSelected] = useState<string[]>(
    data.integrations?.map((i) => i.capability_key) || [],
  );

  const integrationList = useMemo(
    () => [
      {
        key: 'sienge_plataform',
        name: 'Sienge Plataforma',
      },
    ],
    [],
  );

  const [useFlow, setUseFlow] = useState(
    !!data.use_flow_capability_id || false,
  );

  const [useParametricBudget, setUseParametricBudget] = useState(
    !!data.use_parametric_budget_capability_id || false,
  );

  const [useTemplate, setUseTemplate] = useState(
    !!data.use_template_capability_id || false,
  );

  const [loading, setLoading] = useState(false);

  const [isFindingBases, setIsFindingBases] = useState(false);

  const [errors, setErrors] = useState<FormErrors | undefined>();

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, []);

  const basesToSelect = useMemo(
    () =>
      baseList.map((base) => ({
        value: base.id,
        label: base.description,
      })),
    [baseList],
  );

  const integrationsToSelect = useMemo(
    () =>
      integrationList.map((integration) => ({
        value: integration.key,
        label: integration.name,
      })),
    [integrationList],
  );

  const getBases = useCallback(async () => {
    setIsFindingBases(true);
    try {
      const { data: responseStates } = await api.get(`/base`, {
        params: {
          sort: 'description',
          'filter[no_organization]': '.',
        },
      });
      const states = responseStates.data;
      setBaseList(states);
    } catch (e) {
      toast({
        description: 'Houve um erro ao tentar carregar a lista de estados.',
        status: 'error',
      });
    } finally {
      setIsFindingBases(false);
    }
  }, []);

  useEffect(() => {
    getBases();
  }, [getBases]);

  useEffect(() => {
    if (baseSelected.length === basesToSelect.length) {
      setIsAllBases(true);
    } else {
      setIsAllBases(false);
    }
  }, [baseSelected, basesToSelect]);

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (baseSelected.length === 0) {
      Object.assign(formErrors, { states: true, type: 'required' });
    }

    if (!description) {
      Object.assign(formErrors, { description: true, type: 'required' });
    }

    if (!externalId) {
      Object.assign(formErrors, { externalId: true, type: 'required' });
    }

    if (!numberOfUsers || numberOfUsers <= 0) {
      Object.assign(formErrors, { numberOfUsers: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      if (mode === 'add') {
        const {
          data: {
            data: { id: planId },
          },
        } = await api.post(`/plan`, {
          type: 'manual',
          external_id: externalId,
          description,
        });

        const payload: CapabilityPostPayload = [
          {
            key: 'use_bases',
            value: baseSelected.map(String),
          },
        ];

        if (integrationSelected.length > 0) {
          integrationSelected.forEach((integration) => {
            payload.push({
              key: integration,
              value: 1,
            });
          });
        }

        if (useFlow) {
          payload.push({
            key: 'use_flow',
            value: 1,
          });
        }

        if (useParametricBudget) {
          payload.push({
            key: 'use_parametric_budget',
            value: 1,
          });
        }

        if (useTemplate) {
          payload.push({
            key: 'use_template',
            value: 1,
          });
        }

        if (numberOfUsers) {
          payload.push({
            key: 'limit_users_plan',
            value: Number(numberOfUsers),
          });
        }

        await api.post(`/capability`, {
          object_type: 'plan',
          object_id: planId,
          data: payload,
        });
      } else if (mode === 'edit') {
        await api.put(`/plan/${data.plan_id}`, {
          type: 'manual',
          external_id: externalId,
          description,
        });

        const integrationKeys = data.integrations.map(
          (integration) => integration.capability_key,
        );

        const integrationsToRemove = getMissingElements(
          integrationKeys,
          integrationSelected,
        );

        if (integrationsToRemove.length > 0) {
          integrationsToRemove.forEach(async (integration) => {
            const capabilityId = data.integrations?.find(
              (item) => item.capability_key === integration,
            )?.capability_id;

            await api.delete(`/capability/${capabilityId}`, {
              params: {
                object_type: 'plan',
                object_id: data.plan_id,
              },
            });
          });
        }

        const integrationsToAdd = getMissingElements(
          integrationSelected,
          integrationKeys,
        );

        const putPayload: CapabilityPutPayload = [
          {
            id: Number(data.use_bases_capability_id),
            value: baseSelected.map(String),
          },
        ];

        if (numberOfUsers) {
          putPayload.push({
            id: Number(data.number_of_users_capability_id),
            value: Number(numberOfUsers),
          });
        }

        const postPayload: CapabilityPostPayload = [];
        if (integrationsToAdd.length > 0) {
          integrationsToAdd.forEach((integration) => {
            postPayload.push({
              key: integration,
              value: 1,
            });
          });
        }

        if (!data.use_flow_capability_id && useFlow) {
          postPayload.push({
            key: 'use_flow',
            value: 1,
          });
        }

        if (data.use_flow_capability_id && !useFlow) {
          await api.delete(`/capability/${data.use_flow_capability_id}`, {
            params: {
              object_type: 'plan',
              object_id: data.plan_id,
            },
          });
        }

        if (!data.use_parametric_budget_capability_id && useParametricBudget) {
          postPayload.push({
            key: 'use_parametric_budget',
            value: 1,
          });
        }

        if (data.use_parametric_budget_capability_id && !useParametricBudget) {
          await api.delete(
            `/capability/${data.use_parametric_budget_capability_id}`,
            {
              params: {
                object_type: 'plan',
                object_id: data.plan_id,
              },
            },
          );
        }

        if (!data.use_template_capability_id && useTemplate) {
          postPayload.push({
            key: 'use_template',
            value: 1,
          });
        }

        if (data.use_template_capability_id && !useTemplate) {
          await api.delete(`/capability/${data.use_template_capability_id}`, {
            params: {
              object_type: 'plan',
              object_id: data.plan_id,
            },
          });
        }

        if (postPayload.length > 0) {
          await api.post(`/capability`, {
            object_type: 'plan',
            object_id: data.plan_id,
            data: postPayload,
          });
        }

        await api.put(`/capability`, {
          object_type: 'plan',
          object_id: data.plan_id,
          data: putPayload,
        });
      }

      if (onConfirm) onConfirm();

      toast({
        description:
          mode === 'add'
            ? 'Plano adicionado com sucesso!'
            : 'Plano editado com sucesso!',
        status: 'success',
      });

      handleClose();
    } catch (err) {
      toast({
        description:
          mode === 'add'
            ? 'Houve um erro ao adicionar o plano!'
            : 'Houve um erro ao editar o plano!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add' ? 'Adicionar plano' : 'Editar plano'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors?.description}>
            <FormLabel>Descrição</FormLabel>

            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyPress={(e) =>
                e.key === 'Enter' && externalIdRef.current?.focus()
              }
              ref={descriptionRef}
            />

            <FormErrorMessage>
              {!!errors?.description && 'Descrição é obrigatória'}
            </FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.externalId}>
            <FormLabel>ID Externo</FormLabel>

            <Input
              type="text"
              value={externalId}
              onChange={(e) => setExternalId(e.target.value)}
              onKeyPress={(e) =>
                e.key === 'Enter' && numberOfUsersRef.current?.focus()
              }
              ref={externalIdRef}
            />

            <FormErrorMessage>
              {!!errors?.externalId && 'ID Externo é obrigatória'}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.states} mt={6}>
            <FormLabel>
              Bases{' '}
              <Checkbox
                mt={1}
                ml={5}
                mr={1}
                isChecked={isAllBases}
                onChange={(e) => {
                  setIsAllBases(e.target.checked);
                  if (e.target.checked) {
                    setBaseSelected(basesToSelect.map((state) => state.value));
                  }
                }}
                size="sm"
              />{' '}
              Todos
            </FormLabel>

            <OwnSelect
              placeholder="Selecione"
              options={basesToSelect}
              isInvalid={!!errors?.states}
              isMulti
              value={basesToSelect.filter((s) =>
                baseSelected.includes(s.value),
              )}
              isLoading={isFindingBases}
              isDisabled={isFindingBases || basesToSelect.length === 0}
              isClearable
              onChange={(selected: SelectedValues) => {
                const value = selected.map((s) => Number(s.value));
                setBaseSelected(value);
              }}
            />
            <FormErrorMessage>Base é obrigatória</FormErrorMessage>
          </FormControl>

          <FormControl mt={6}>
            <FormLabel>Integrações</FormLabel>

            <OwnSelect
              placeholder="Selecione"
              options={integrationsToSelect}
              isInvalid={!!errors?.states}
              isMulti
              value={integrationsToSelect.filter((s) =>
                integrationSelected.includes(s.value),
              )}
              isDisabled={integrationsToSelect.length === 0}
              isClearable
              onChange={(selected: SelectedValues) => {
                const value = selected.map((s) => s.value);
                setIntegrationSelected(value);
              }}
            />
            <FormErrorMessage>Integrações é obrigatória</FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.numberOfUsers}>
            <FormLabel>Número de usuários</FormLabel>

            <Input
              type="number"
              value={numberOfUsers || ''}
              onChange={(e) => setNumberOfUsers(Number(e.target.value))}
              ref={numberOfUsersRef}
            />

            <FormErrorMessage>
              {!!errors?.numberOfUsers && 'Número de usuários é obrigatória'}
            </FormErrorMessage>
          </FormControl>

          <Grid
            templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
            columnGap={2}
            rowGap={2}
            width="100%"
          >
            <FormControl mt={6}>
              <Checkbox
                isChecked={useFlow}
                onChange={(e) => setUseFlow(e.target.checked)}
              >
                Habilitar CRM / Flow
              </Checkbox>
            </FormControl>

            <FormControl mt={6}>
              <Checkbox
                isChecked={useTemplate}
                onChange={(e) => setUseTemplate(e.target.checked)}
              >
                Habilitar Loja de Templates
              </Checkbox>
            </FormControl>
          </Grid>

          <FormControl mt={6}>
            <Checkbox
              isChecked={useParametricBudget}
              onChange={(e) => setUseParametricBudget(e.target.checked)}
            >
              Habilitar Orçamento Paramétrico
            </Checkbox>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalPlanAdd;
