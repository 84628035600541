import axios, { AxiosError, AxiosInstance } from 'axios';

type ApiClientOptions = {
  baseURL: string;
};

export const createApiClient = (options: ApiClientOptions): AxiosInstance => {
  const client = axios.create({
    baseURL: options.baseURL,
    headers: {
      Accept: 'application/json',
    },
  });

  return client;
};

export const isAxiosError = (e: unknown): e is AxiosError => {
  if (typeof e !== 'object') return false;
  if (e == null) return false;
  return 'response' in e;
};

export const getErrors = (e?: string[]): string => {
  return e ? e.join(', ') : '';
};
