import React, { createContext } from 'react';

import useAuth, { Auth, UserOrganization } from 'hooks/useAuth';

const AuthContext = createContext({} as Auth);

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export type { UserOrganization };
export default AuthContext;
