import React from 'react';

import { TableCellProps, Td as ChakraTd } from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

interface ITdInnerProps extends TableCellProps {
  columnKey: number;
  narrowHeaders: Record<number, React.ReactElement>;
  noData?: boolean;
}

export type ITdProps = Omit<ITdInnerProps, 'narrowHeaders'>;

const TdInner = ({
  className,
  children,
  ...restProps
}: ITdInnerProps): React.ReactElement => {
  const {
    narrowHeaders = {},
    columnKey = 0,
    onClick,
    colSpan,
    ...rest
  } = restProps;

  const classes = !restProps?.noData ? className : '';
  const innerOnClick = !restProps?.noData ? onClick : undefined;

  return (
    <ChakraTd data-testid="td" className="pivoted" colSpan={colSpan} {...rest}>
      {!restProps?.noData && colSpan !== 10 && (
        // eslint-disable-next-line
        <div
          data-testid="td-before"
          className={`${classes} tdBefore`}
          onClick={innerOnClick}
        >
          {narrowHeaders[columnKey]}
        </div>
      )}

      {children ?? <div>&nbsp;</div>}
    </ChakraTd>
  );
};

export const Td = (props: ITdProps): JSX.Element => (
  <TableContext.Consumer>
    {(headers) => {
      return <TdInner {...props} narrowHeaders={headers} />;
    }}
  </TableContext.Consumer>
);
