import ModalPlanAdd from 'components/Modal/PlanAdd';
import ModalSubscriptionHistory from 'components/Modal/SubscriptionHistory';
import ModalUnitMeasureAdd from 'components/Modal/UnitMeasureAdd';
import ModalUserInvite from 'components/Modal/UserInvite';
import ModalUserOrganizationInvite from 'components/Modal/UserOrganizationInvite';

export default {
  planAdd: ModalPlanAdd,
  subscriptionHistory: ModalSubscriptionHistory,
  userInvite: ModalUserInvite,
  userOrganizationInvite: ModalUserOrganizationInvite,
  unitMeasureAdd: ModalUnitMeasureAdd,
};
