export const getPaymentMethod = (paymentMethod: string): string => {
  if (paymentMethod === 'credit_card') {
    return 'Cartão de crédito';
  }

  if (paymentMethod === 'bankin_ticket') {
    return 'Boleto';
  }

  if (paymentMethod === 'pix') {
    return 'Pix';
  }

  return 'Não identificado';
};
