import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

import FallbackSpinner from 'components/FallbackSpinner';

import AuthContext from 'contexts/AuthContext';
import { SidebarProvider } from 'contexts/SidebarContext';

const AuthRoutes = lazy(() => import('./AuthRoutes'));
const AppRoutes = lazy(() => import('./AppRoutes'));

const Routes: React.FC = () => {
  const location = useLocation();

  const { authenticated, validating } = useContext(AuthContext);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [location.pathname]);

  if (validating) {
    return <FallbackSpinner />;
  }

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <SidebarProvider>
        {authenticated ? <AppRoutes /> : <AuthRoutes />}
      </SidebarProvider>
    </Suspense>
  );
};

export default withRouter(Routes);
