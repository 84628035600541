import { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  variants: {
    ecustos: {
      field: {
        background: '#FFF',
        color: 'blackAplha',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'gray.300',
        errorBorderColor: 'red.300',
        transitionDuration: '100ms',
        _readOnly: {
          bg: `gray.100`,
        },
        _focus: {
          borderColor: 'blue.300',
        },
        _invalid: {
          borderColor: 'red.500',
          background: 'red.100',
          _placeholder: {
            color: 'red.600',
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'ecustos',
  },
};
