import React from 'react'; // { Suspense, lazy }
import ReactDOM from 'react-dom';

import { ColorModeScript } from '@chakra-ui/react';

// import FallbackSpinner from 'components/FallbackSpinner';
import App from './App';

// const LazyApp = lazy(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />

    {/* <Suspense fallback={<FallbackSpinner />}> */}
    <App />
    {/* </Suspense> */}
  </React.StrictMode>,
  document.getElementById('root'),
);
