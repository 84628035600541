import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import Routes from 'routes';

import { ChakraProvider } from '@chakra-ui/react';

import AlertRoot from 'components/Alert/Root';
import ModalRoot from 'components/Modal/Root';

import { AlertProvider } from 'contexts/AlertContext';
import { AuthProvider } from 'contexts/AuthContext';
import { ModalProvider } from 'contexts/ModalContext';

import customTheme from 'styles/theme/customTheme';

import 'react-perfect-scrollbar/dist/css/styles.css';

import 'styles/css/all.scss';

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={customTheme}>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <AuthProvider>
              <ModalProvider>
                <AlertProvider>
                  <Routes />
                  <ModalRoot />
                  <AlertRoot />
                </AlertProvider>
              </ModalProvider>
            </AuthProvider>
          </DndProvider>
        </BrowserRouter>
      </ChakraProvider>

      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
};

export default App;
