import { useCallback, useState } from 'react';

import Cookies from 'js-cookie';

type Value = string | null;
type UpdateCookie = (
  newValue: string,
  options?: Cookies.CookieAttributes,
) => void;
type DeleteCookie = () => void;

const useCookie = (cookieName: string): [Value, UpdateCookie, DeleteCookie] => {
  const [value, setValue] = useState<Value>(
    () => Cookies.get(cookieName) || null,
  );

  const updateCookie = useCallback(
    (newValue: string, options?: Cookies.CookieAttributes) => {
      Cookies.set(cookieName, newValue, options);
      setValue(newValue);
    },
    [cookieName],
  );

  const deleteCookie = useCallback(() => {
    Cookies.remove(cookieName);
    setValue(null);
  }, [cookieName]);

  return [value, updateCookie, deleteCookie];
};

export default useCookie;
