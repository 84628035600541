import React, { useEffect, useState } from 'react';

import { api } from 'services/api';
import { toast } from 'shared/toast';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

type FormErrors = {
  [key: string]: string | boolean;
  type: 'invalid' | 'required';
};

interface ModalUnitMeasureProps extends ModalRootProps {
  data: {
    unit_measure_id?: number;
    description?: string;
    key?: string;
  };
}

const ModalUnitMeasureAdd: React.FC<ModalUnitMeasureProps> = ({
  onConfirm,
  handleClose,
  data,
  mode,
  ...restProps
}) => {
  const [description, setDescription] = useState(data.description || '');
  const [key, setKey] = useState(data.key || '');

  const descriptionRef = React.useRef<HTMLInputElement>(null);
  const keyRef = React.useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<FormErrors | undefined>();

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, []);

  const handleConfirm = async (): Promise<void> => {
    const formErrors: FormErrors = {} as FormErrors;

    if (!description) {
      Object.assign(formErrors, { description: true, type: 'required' });
    }

    if (!key) {
      Object.assign(formErrors, { key: true, type: 'required' });
    }

    setErrors(formErrors);
    if (Object.keys(formErrors).length) {
      return;
    }

    try {
      setLoading(true);

      if (mode === 'add') {
        await api.post(`/unit/measure`, {
          description,
          key,
        });
      } else if (mode === 'edit') {
        await api.put(`/unit/measure/${data.unit_measure_id}`, {
          description,
          key,
        });
      }

      if (onConfirm) onConfirm();

      toast({
        description:
          mode === 'add'
            ? 'Unidade de medida adicionada com sucesso!'
            : 'Unidade de medida editada com sucesso!',
        status: 'success',
      });

      handleClose();
    } catch (err) {
      toast({
        description:
          mode === 'add'
            ? 'Houve um erro ao adicionar a unidade de medida!'
            : 'Houve um erro ao editar a unidade de medida!',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...restProps} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {mode === 'add'
            ? 'Adicionar unidade de medida'
            : 'Editar unidade de medida'}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!errors?.description}>
            <FormLabel>Descrição</FormLabel>

            <Input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && keyRef.current?.focus()}
              ref={descriptionRef}
            />

            <FormErrorMessage>
              {!!errors?.description && 'Descrição é obrigatória'}
            </FormErrorMessage>
          </FormControl>

          <FormControl marginTop="4" isInvalid={!!errors?.key}>
            <FormLabel>Abreviação</FormLabel>

            <Input
              type="text"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleConfirm()}
              ref={keyRef}
            />

            <FormErrorMessage>
              {!!errors?.key && 'Abreviação é obrigatória'}
            </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            isLoading={loading}
            isDisabled={loading}
            colorScheme="green"
            onClick={handleConfirm}
          >
            Salvar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalUnitMeasureAdd;
