import React from 'react';
import ReactSelect, { Props as ReactSelectProps } from 'react-select';

import { Box } from '@chakra-ui/react';

import DropdownIndicator from './components/DropdownIndicator';
import LoadingIndicator from './components/LoadingIndicator';

export type SelectedValue = {
  value: string;
  label: string;
};

export type SelectedValues = SelectedValue[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OwnSelectProps = ReactSelectProps<any> & {
  isInvalid?: boolean;
};

const OwnSelect: React.FC<OwnSelectProps> = (props) => {
  const { isInvalid } = props;

  return (
    <Box
      sx={{
        '& .react-select__control': {
          zIndex: 2,
          paddingX: '0.5rem',
          boxShadow: 'none',

          backgroundColor: isInvalid ? 'red.100' : 'white',
          borderColor: isInvalid ? 'red.500' : undefined,

          '&.react-select__control--is-focused': {
            borderColor: isInvalid ? 'red.500' : 'blue.300',
          },

          '& .react-select__placeholder': {
            color: isInvalid ? 'red.500' : undefined,
          },

          '& .react-select__value-container': {
            '& .react-select__multi-value': {
              overflow: 'hidden',

              bg: 'gray.100',

              '& .react-select__multi-value__label': {
                paddingX: 2,
              },
              '& .react-select__multi-value__remove': {
                bg: 'gray.300',
                _hover: {
                  bg: 'red.100',
                  color: 'red.900',
                  cursor: 'pointer',
                },
              },
            },
          },
        },
        '& .react-select__menu': {
          overflow: 'hidden',
          zIndex: 3,

          outline: 0,
          borderWidth: 2,
          boxShadow: 'dropdown',

          borderColor: 'gray.100',

          '& .react-select__menu-list': {
            padding: 2,

            '& .react-select__option': {
              borderRadius: 12,

              transition: 'all 300ms ease',
              bg: 'transparent',
              color: 'blackAlpha.800',

              '&.react-select__option--is-focused': {
                bg: 'gray.50',
                cursor: 'pointer',
              },
            },
          },
        },
      }}
    >
      <ReactSelect
        {...props}
        components={{
          DropdownIndicator,
          LoadingIndicator,
          IndicatorSeparator: null,
        }}
        classNamePrefix="react-select"
      />
    </Box>
  );
};

export default OwnSelect;
