import React, { ReactElement } from 'react';

import { TableRowProps, Tr as ChakraTr } from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

interface ITrInnerProps extends TableRowProps {
  headers: Record<number, React.ReactElement>;
  inHeader: boolean;
}

export type ITrProps = Omit<ITrInnerProps, 'headers'>;

const TrInner = (props: ITrInnerProps): React.ReactElement => {
  const { headers, children, inHeader, ...rest } = props;

  if (headers && inHeader) {
    React.Children.map(props.children, (child, i) => {
      if (child) {
        headers[i] = (child as ReactElement).props.children;
      }
    });
  }

  const childProps = (idx: number): React.ReactElement =>
    inHeader ? { key: idx } : { key: idx, columnKey: idx };

  return (
    <ChakraTr {...rest}>
      {children &&
        React.Children.map(
          children,
          (child, idx) =>
            React.isValidElement(child) &&
            React.cloneElement(child, childProps(idx)),
        )}
    </ChakraTr>
  );
};

export const Tr = (props: ITrProps): React.ReactElement => (
  <TableContext.Consumer>
    {(headers) => <TrInner {...props} headers={headers} />}
  </TableContext.Consumer>
);
