import { ComponentStyleConfig } from '@chakra-ui/react';

export const FormLabel: ComponentStyleConfig = {
  baseStyle: {
    fontSize: 'sm',
    _invalid: {
      color: 'red.500',
    },
  },
};

export const FormError: ComponentStyleConfig = {
  baseStyle: {
    text: {
      color: 'red.500',
      mb: 3,
    },
  },
};
