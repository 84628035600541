export const sidebarWidth = 300;
export const headerHeight = 80;

export const sidebarClosedWidth = 0;
export const sidebarOpenWidth = 300;

export default {
  cssVarPrefix: 'ecustos',
  defaultColorMode: 'light',
  useSystemColorMode: false,
};
