import { createStandaloneToast } from '@chakra-ui/react';

export const toast = createStandaloneToast({
  defaultOptions: {
    position: 'top-right',
    variant: 'solid',
    isClosable: true,
    duration: 5000,
  },
});
