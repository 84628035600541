import { ComponentStyleConfig } from '@chakra-ui/react';

export const Table: ComponentStyleConfig = {
  defaultProps: {
    size: 'sm',
    variant: 'striped',
  },
};

export const Td: ComponentStyleConfig = {
  baseStyle: {
    bg: 'gray.400',
  },
};
