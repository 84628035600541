import React from 'react';

import { Table as ChakraTable, TableProps } from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

export const Table = (props: TableProps): React.ReactElement => {
  const { className, ...rest } = props;
  const classes = `${className || ''} table-responsible`;

  return (
    <TableContext.Provider value={{}}>
      <ChakraTable {...rest} className={classes} />
    </TableContext.Provider>
  );
};
